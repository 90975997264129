var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.visible)?_c('div',{staticClass:"backdrop-overlay",on:{"click":function () {
        _vm.visible = false
      }}}):_vm._e(),_c('div',{class:[
      'popup-button',
      {
        disabled: _vm.disabled,
        error: _vm.error,
      } ],attrs:{"tabindex":!_vm.disabled ? '0' : null},on:{"keypress":_vm.onClickButton,"click":_vm.onClickButton}},[_c('span',{staticClass:" w-12 cursor-pointer pointer-events-none"},[_vm._v(_vm._s(_vm.$attrs.value))]),_c('ClockIcon',{staticClass:"absolute my-auto mr-2 top-0 right-0 bottom-0 transform"})],1),(_vm.visible)?_c('div',{staticClass:"z-50 flex flex-col gap-4 popup-wrapper w-min p-4"},[_c('div',{staticClass:"flex gap-4 justify-center"},[_c('div',{staticClass:"flex flex-col w-24 gap-2"},[_c('TextInput',{attrs:{"id":"hour-field","customClass":"text-center","type":"text","maxlength":"2","pattern":"[0-9]{2}"},on:{"input":_vm.onInputHour,"blur":_vm.onBlur},model:{value:(_vm.time.hour),callback:function ($$v) {_vm.$set(_vm.time, "hour", $$v)},expression:"time.hour"}}),_c('span',{staticClass:"text-xs text-center text-gray-1"},[_vm._v("Jam (24 Format)")])],1),_c('span',{staticClass:"text-5xl"},[_vm._v(":")]),_c('div',{staticClass:"flex flex-col w-24 gap-2"},[_c('TextInput',{attrs:{"id":"minute-field","customClass":"text-center","type":"text","maxlength":"2","pattern":"[0-9]{2}","disabled":true},model:{value:(_vm.time.minute),callback:function ($$v) {_vm.$set(_vm.time, "minute", $$v)},expression:"time.minute"}}),_c('span',{staticClass:"text-xs text-center text-gray-1"},[_vm._v("Menit")])],1)]),_c('div',{staticClass:"flex justify-between items-center"},[_c('button',{staticClass:"text-bgPrimary font-semibold",on:{"click":_vm.onReset}},[_vm._v(" RESET ")]),_c('div',{staticClass:"flex items-center gap-4"},[_c('button',{staticClass:"text-bgPrimary font-semibold",on:{"click":_vm.onCancel}},[_vm._v(" BATAL ")]),_c('Button',{on:{"click":_vm.onSet,"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.onSet($event)}}},[_vm._v(" OK ")])],1)])]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }


















































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import Button from '@/app/ui/components/Button/index.vue'
import TextInput from '@/app/ui/components/TextInput/index.vue'
import ClockIcon from '@/app/ui/assets/icon_clock_fill.vue'

@Component({
  components: {
    Button,
    TextInput,
    ClockIcon,
  },
})
export default class PopupBox extends Vue {
  @Prop({ default: false }) private disabled!: boolean
  @Prop({ default: false }) private error!: boolean

  visible = false

  time: Record<string, string> = {
    hour: this.$attrs.value.split(':')[0],
    minute: this.$attrs.value.split(':')[1],
    mainValue: this.$attrs.value,
  }

  private hasSlot(slotName: string): boolean {
    return !!this.$slots[slotName] || !!this.$scopedSlots[slotName]
  }

  private onInputHour(event: string): void {
    const value = event.replace(/[^0-9]/g, '')

    if (Number(value) > 24) {
      this.time.hour = '24'
    } else if (Number(value) < 0) {
      this.time.hour = '00'
    } else {
      this.time.hour = value
    }
  }

  private onBlur(): void {
    this.time.hour = this.time.hour.padStart(2, '0')

    if (this.time.hour === '24') {
      this.time.hour = '23'
      this.time.minute = '59'
    } else {
      this.time.minute = '00'
    }
  }

  private onSet(): void {
    this.time.mainValue = `${this.time.hour}:${this.time.minute}`
    this.$emit('input', this.time.mainValue)
    this.visible = false
  }

  private onReset(): void {
    this.time.mainValue = '00:00'
    this.time.hour = '00'
    this.time.minute = '00'
  }

  private onCancel(): void {
    this.visible = false
  }

  private onClickButton(): void {
    if (this.disabled) return
    this.visible = !this.visible
  }

  @Watch('visible')
  resetTime(): void {
    if (this.visible === false) {
      this.time.mainValue = this.$attrs.value
      this.time.hour = this.$attrs.value.split(':')[0]
      this.time.minute = this.$attrs.value.split(':')[1]
    }
  }
}

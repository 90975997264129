























































































































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import { Validations } from 'vuelidate-property-decorators'
import { validationMixin } from 'vuelidate'
import Button from '@/app/ui/components/Button/index.vue'
import Badge from '@/app/ui/components/Badge/index.vue'
import DataTableV2 from '@/app/ui/components/DataTableV2/index.vue'
import DropdownSelect from '@/app/ui/components/DropdownSelect/index.vue'
import TextInput from '@/app/ui/components/TextInput/index.vue'
import LoadingOverlay from '@/app/ui/components/LoadingOverlay/index.vue'
import IconMinusCircle from '@/app/ui/assets/icon_minus_circle.vue'
import WarningIcon from '@/app/ui/assets/icon_warning_circle.vue'
import TrashIcon from '@/app/ui/assets/trash_icon_alt.vue'
import AddIcon from '@/app/ui/assets/add_icon.vue'
import CalendarIcon from '@/app/ui/assets/icon_calendar_2.vue'
import CheckBox from '../../components/CheckBox/index.vue'
import TimePicker from '../../components/TimePicker/index.vue'
import ModalConfirm from '../../components/Modals/ModalConfirm/index.vue'
import ModalSuccess from '../../components/Modals/ModalSuccess/index.vue'
import dayjs from 'dayjs'
import dayjsID from 'dayjs/locale/id'
import {
  EnumEditAction,
  EnumSort,
  IEditForm,
  IOptions,
  IParams,
  sortOptions,
  ISchedule,
  ScheduleOrderPayload,
  statusOptions,
} from '@/app/infrastructures/misc/Constants/scheduleOrder'
import controller from '@/app/ui/controllers/ScheduleOrderController'
import { ScheduleOrder } from '@/domain/entities/ScheduleOrder'
import { EnumStatus } from '@/app/infrastructures/misc/Constants/manageCaptain'
import { EventBus, EventBusConstants } from '@/app/infrastructures/misc'

@Component({
  mixins: [validationMixin],
  components: {
    Button,
    Badge,
    DataTableV2,
    DropdownSelect,
    TextInput,
    LoadingOverlay,
    IconMinusCircle,
    WarningIcon,
    TrashIcon,
    AddIcon,
    CalendarIcon,
    CheckBox,
    TimePicker,
    ModalConfirm,
    ModalSuccess,
  },
})
export default class ScheduleOrderEditPage extends Vue {
  controller = controller
  modalConfirmVisible = false
  modalSuccessVisible = false

  statusOptions: Array<IOptions> = statusOptions
  sortOptions: Array<IOptions> = sortOptions
  visible = false
  isChanged = false

  parameters: IParams = {
    status: this.statusOptions[0],
    sort: this.sortOptions[0],
  }

  form: IEditForm = {
    days: [],
    schedule: [],
    isActive: this.statusOptions[0],
    updatedAt: '22 Jul 2022, 19:00 WIB',
    updatedBy: 'admin@thelionparcel',
  }

  confirmModal: Record<string, string> = {
    currAction: '',
    title: '',
    desc: '',
    cancelLabel: '',
    actionLabel: '',
  }

  dayList: Array<string> = [
    ...new Array(7).fill('').map((item, index) => {
      const dayNum = index + 1 === 8 ? 0 : index + 1
      return dayjs()
        .locale(dayjsID)
        .day(dayNum)
        .format('dddd')
    }),
  ]

  created(): void {
    EventBus.$on(
      EventBusConstants.EDIT_SCHEDULE_ORDER_SUCCESS,
      (response: boolean) => {
        if (response) {
          this.modalSuccessVisible = true
        }
      }
    )

    this.form.days.push(Number(this.$route.params.id))
    this.fetchScheduleOrder()
  }

  get params(): Record<string, string> {
    const res: Record<string, string> = {
      sort_by: this.parameters.sort.value
        ? this.parameters.sort.value
        : EnumSort.ASC,
    }

    if (this.parameters.status.value !== undefined) {
      res.is_active = this.parameters.status.value
    }

    return res
  }

  get isLoading(): boolean {
    return controller.isLoading || controller.isUpdateLoading
  }

  private fetchScheduleOrder(): void {
    controller.getAll(this.params)
  }

  private onAddSchedule(): void {
    this.isChanged = true

    this.form.schedule.push({
      id: this.form.schedule[this.form.schedule.length - 1].id + 1,
      startTime: '00:00',
      endTime: '23:59',
      offerTime: '23:59',
    })
  }

  private onDeleteSchedule(index: number): void {
    this.isChanged = true
    this.form.schedule.splice(index, 1)
  }

  private onCancel(): void {
    this.setConfirmMessage(
      EnumEditAction.CANCEL,
      'Batalkan Edit Jadwal?',
      'Data yang sudah Anda edit akan dihapus',
      'Kembali',
      'Ya, Batal'
    )
    this.modalConfirmVisible = true
  }

  private onSubmit(): void {
    const selectedDay = this.form.days
      .map(dayNum => this.dayList[dayNum])
      .join(', ')

    this.setConfirmMessage(
      EnumEditAction.SUBMIT,
      `Simpan Jadwal untuk Hari ${selectedDay}?`,
      'Pastikan waktu jadwal yang diubah telah sesuai ',
      'Cek Ulang',
      'Simpan'
    )

    this.modalConfirmVisible = true
  }

  private onConfirmAction(
    action: EnumEditAction.CANCEL | EnumEditAction.SUBMIT
  ): void {
    this.modalConfirmVisible = false

    if (action === EnumEditAction.CANCEL) {
      this.$router.push('/offer/schedule-order')
    } else if (action === EnumEditAction.SUBMIT) {
      const payload: ScheduleOrderPayload = {
        days: this.form.days.map(day => day + 1),
        schedule: this.form.schedule.map(item => {
          return {
            start_time: this.setSecondsToTime(item.startTime),
            end_time: this.setSecondsToTime(item.endTime),
            offer_time: this.setSecondsToTime(item.offerTime),
          }
        }),
        is_active:
          this.form.isActive.value === EnumStatus.ACTIVE ? true : false,
      }

      controller.update(payload)
    }

    this.setConfirmMessage('', '', '', '', '')
  }

  private onSetCheckbox(dayNum: number): void {
    this.isChanged = true

    if (this.form.days.includes(dayNum)) {
      const index = this.form.days.indexOf(dayNum)
      this.form.days.splice(index, 1)
    } else {
      this.form.days.push(dayNum)
    }

    this.form.days.sort()
  }

  private onCloseSuccessModal(): void {
    this.modalSuccessVisible = false
    this.$router.push('/offer/schedule-order')
  }

  private onTimePickerInput(
    key: keyof ISchedule,
    value: string,
    index: number
  ): void {
    this.isChanged = true

    this.form.schedule.splice(index, 1, {
      ...this.form.schedule[index],
      [key]: value,
    })
  }

  private setSecondsToTime(time: string): string {
    if (time === '23:59') {
      return `${time}:59`
    }

    return `${time}:00`
  }

  private setConfirmMessage(
    currAction: string,
    title: string,
    desc: string,
    cancelLabel: string,
    actionLabel: string
  ): void {
    this.confirmModal.currAction = currAction
    this.confirmModal.title = title
    this.confirmModal.desc = desc
    this.confirmModal.cancelLabel = cancelLabel
    this.confirmModal.actionLabel = actionLabel
  }

  private setEndTimeErrorMessage(
    isConflictNext: boolean,
    isLessThanStartTime: boolean
  ): string {
    if (isConflictNext) {
      return 'Waktu bertabrakan dengan rentang waktu lainnya'
    }

    if (isLessThanStartTime) {
      return 'Waktu berakhir tidak boleh lebih kecil dari atau sama dengan waktu mulai'
    }

    return ''
  }

  private isOfferTimeLessThanEndTime(
    endTime: string,
    offerTime: string
  ): boolean {
    return dayjs(`2000-01-01 ${endTime}`).isAfter(
      dayjs(`2000-01-01 ${offerTime}`),
      'minute'
    )
  }

  private checkStartTimeConflictWithPrevious(
    value: ISchedule,
    values: ISchedule[]
  ): boolean {
    const currIdx = values.findIndex((item: ISchedule) => item.id === value.id)
    const prevSchedule = values[currIdx - 1]

    if (prevSchedule) {
      const isCurrStartTimeLessThanPrevEndTime = dayjs(
        `2000-01-01 ${prevSchedule.endTime}`
      ).isAfter(dayjs(`2000-01-01 ${value.startTime}`), 'minute')

      if (isCurrStartTimeLessThanPrevEndTime) {
        return false
      }
    }

    return true
  }

  private checkEndTimeConflictWithNext(
    value: ISchedule,
    values: ISchedule[]
  ): boolean {
    const currIdx = values.findIndex((item: ISchedule) => item.id === value.id)
    const nextSchedule = values[currIdx + 1]

    if (nextSchedule) {
      const isCurrEndTimeMoreThanNextStartTime = dayjs(
        `2000-01-01 ${value.endTime}`
      ).isAfter(dayjs(`2000-01-01 ${nextSchedule.startTime}`), 'minute')

      if (isCurrEndTimeMoreThanNextStartTime) {
        return false
      }
    }

    return true
  }

  private checkEndTimeLessThanStartTime(value: ISchedule): boolean {
    return !(
      dayjs(`2000-01-01 ${value.startTime}`).isAfter(
        dayjs(`2000-01-01 ${value.endTime}`),
        'minute'
      ) ||
      dayjs(`2000-01-01 ${value.startTime}`).isSame(
        dayjs(`2000-01-01 ${value.endTime}`),
        'minute'
      )
    )
  }

  @Validations()
  validations(): Record<string, unknown> {
    return {
      form: {
        daysNotEmpty: (data: IEditForm) => {
          if (data.days.length !== 0) {
            return true
          }
          return false
        },
        schedule: {
          $each: {
            isStartTimeNotConflictWithPrevious: (e: ISchedule) =>
              this.checkStartTimeConflictWithPrevious(e, this.form.schedule),
            isEndTimeNotConflictWithNext: (e: ISchedule) =>
              this.checkEndTimeConflictWithNext(e, this.form.schedule),
            isEndTimeMoreThanStartTime: (e: ISchedule) =>
              this.checkEndTimeLessThanStartTime(e),
          },
        },
      },
    }
  }

  private formatDateTime(dateTime: string): string {
    const formattedDateTime = dayjs(dateTime)
      .locale(dayjsID)
      .format('DD MMM YYYY, HH:mm Z')

    if (formattedDateTime.includes('+07:00'))
      return formattedDateTime.replace('+07:00', 'WIB')
    if (formattedDateTime.includes('+08:00'))
      return formattedDateTime.replace('+08:00', 'WITA')
    if (formattedDateTime.includes('+09:00'))
      return formattedDateTime.replace('+09:00', 'WIT')

    return formattedDateTime
  }

  @Watch('controller.dataScheduleOrder')
  setScheduleOrder(data: ScheduleOrder[]): void {
    if (data) {
      const selectedDay = Number(this.$route.params.id) + 1
      const selectedData = data.find(item => item.day === selectedDay)
      const status = selectedData?.isActive
        ? EnumStatus.ACTIVE
        : EnumStatus.INACTIVE

      this.form = {
        days: [Number(this.$route.params.id)],
        schedule: !selectedData?.schedule
          ? []
          : selectedData.schedule.map((item, index) => {
              return {
                id: index,
                startTime: item.startTime ? item.startTime.substring(0, 5) : '',
                endTime: item.endTime ? item.endTime.substring(0, 5) : '',
                offerTime: item.offerTime ? item.offerTime.substring(0, 5) : '',
              }
            }),
        isActive:
          this.statusOptions.find(item => item.value === status) ||
          this.statusOptions[1],
        updatedAt: selectedData?.updatedAt
          ? this.formatDateTime(String(selectedData?.updatedAt))
          : '',
        updatedBy: selectedData?.updatedBy || '',
      }
    }
  }

  beforeDestroy(): void {
    EventBus.$off(EventBusConstants.EDIT_SCHEDULE_ORDER_SUCCESS)
  }
}
